<script lang="ts" setup>
import type { BlogDeCollectionItem } from '@nuxt/content';

const props = defineProps<{
  blogPost: Pick<BlogDeCollectionItem, 'path' | 'title' | 'image' | 'description'>
}>();

const imageUrl = computed(() => props.blogPost?.image ?? '/img/no-image.png');
</script>

<template>
  <NuxtLink :to="blogPost.path">
    <div class="blog-post-card">
      <div class="blog-post-card-image-wrapper">
        <nuxt-img
          class="blog-post-card-image"
          :src="imageUrl"
          width="600"
          height="400"
          alt=""
          format="webp"
        />
      </div>
      <div class="blog-post-card-content has-background-light">
        <h3 class="is-size-5-mobile has-text-dark">
          {{ blogPost.title }}
        </h3>
        <p class="has-text-grey-dark py-1 my-1">
          {{ blogPost.description }}
        </p>
      </div>
    </div>
  </NuxtLink>
</template>

<style lang="scss" scoped>
.blog-post-card {
  display: flex;
  flex-direction: column;
  max-width: 400px;
}

.blog-post-card-image-wrapper {
  display: flex;
}

.blog-post-card-image {
  max-width: 100%;
  height: 100%;
  object-fit: fill;
  border-radius: 10px 10px 0 0;
}

.blog-post-card-content {
  min-height: 250px;
  padding: 10px 20px;
  border-radius: 0 0 10px 10px;
}
</style>
